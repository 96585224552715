import React, { Component } from "react";
import Typing from "react-typing-animation";
import Prismic from "prismic.io";
import MediaQuery from "react-responsive";
import Slider from "react-animated-slider";
import { Blob } from "react-blob";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/lib/components/slideshow/fade.css";
import ReactFitText from "react-fittext";
import { Layout, Root, Grid, Dev, Col } from "gymnast";
import ProgressiveImage from "react-progressive-image";
import uniqueRandomArray from "unique-random-array";
import config from "../../config";
import styles from "./styles.module.css";
import "./styles.css";
import Button from "../Button";
import { Parallax, ParallaxBanner } from "react-scroll-parallax";
import { ReactComponent as HeroLayer } from "../Misc/HeroLayer.svg";
import { ReactComponent as QuoteLayer } from "../Misc/QuoteLayer.svg";

const Image = ({ src, placeholder, alt }) => (
  <ProgressiveImage src={src} placeholder={placeholder}>
    {(src, loading) => (
      <img
        className={!loading ? styles.progressive : styles.isLoading}
        src={src}
        alt={alt}
      />
    )}
  </ProgressiveImage>
);

const TypesQuote = ({ quote }) => (
  <React.Fragment>
    <Typing hideCursor>
      <span>{quote}</span>
      <Typing.Reset count={1} delay={500} />
    </Typing>
  </React.Fragment>
);

const size = {
  large: 12,
  medium: 12,
  small: 1
};

const colSize = {
  large: 6,
  medium: 12
};

export default class QuoteGenerator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quotes: null,
      quote:
        "Sadness gives depth. Happiness gives height. Sadness gives roots. Hapiness gives branches. Hapiness is like a tree going into the sky. and sadness is like the roots going down into womb of the earth. Both are needed, and the higher a tree goes, the deeper it goes, simultaneously. The bigger the tree, the bigger will be its roots. In fact, it's always in proportion. That's balance.",
      author: "Osho",
      image:
        "https://images.unsplash.com/photo-1453791052107-5c843da62d97?ixlib=rb-0.3.5&s=8dc6d22899c29ad55c789a6e830fa078&auto=format&fit=crop&w=2550&q=80",
      placeholder: null,
      loaded: false
    };

    this.generateQuote = this.generateQuote.bind(this);
    this.getQuotes = this.getQuotes.bind(this);
  }

  async componentDidMount() {
    this.getQuotes();
  }

  async getQuotes() {
    try {
      const call = await Prismic.api(config.api);
      const query = await call.query([
        Prismic.Predicates.at("document.type", "quotes")
      ]);

      console.log(query);

      const quotes = query.results.map(quote => ({
        quote: quote.rawJSON.quote[0].text,
        author: quote.rawJSON.author[0].text,
        image: quote.rawJSON.image.url,
        placeholder: quote.rawJSON.image.placeholder.url
      }));

      this.setState({
        quotes,
        loaded: true
      });
    } catch (error) {
      console.log(error);
    }
  }

  generateQuote() {
    const { quotes } = this.state;
    // const random = Math.floor(Math.random() * quotes.length);
    // const quote = quotes[random];
    const quote = uniqueRandomArray(quotes)();
    console.log(quote);

    this.setState({
      quote: quote.quote,
      author: quote.author,
      image: quote.image,
      placeholder: quote.placeholder
    });

    return null;
  }

  render() {
    const { image, loaded, placeholder, quotes } = this.state;

    const properties = {
      duration: 50000000,
      transitionDuration: 500,
      infinite: true,
      indicators: false,
      arrows: true
    };

    if (!quotes) {
      return <div>Loading quotes</div>;
    }

    return (
      <div className={styles.wrapper}>
        <Fade {...properties} className={styles.slider}>
          {quotes.map((quote, i) => (
            <Grid className={`${styles.slide} each-fade`} key={i}>
              <MediaQuery minWidth={900}>
                <Col
                  className={`${styles.image} ${styles.col}`}
                  size={{ large: 6 }}
                  show={["large"]}
                >
                  <Image
                    src={quote.image}
                    alt="Innergy Quote"
                    placeholder={quote.placeholder}
                  />
                </Col>
              </MediaQuery>
              <Col
                className={`${styles.col} ${styles.quoteWrapper}`}
                size={{ large: 6, medium: 12, small: 12 }}
              >
                <Col show={["medium", "small"]} className={styles.SmallImage}>
                  <Image
                    src={quote.image}
                    alt="Innergy Quote"
                    placeholder={quote.placeholder}
                  />
                </Col>
                <ReactFitText
                  compressor={2.5}
                  minFontSize={16}
                  maxFontSize={21}
                >
                  <p className={styles.quote}>{quote.quote}</p>
                </ReactFitText>
                {/* <p>-{quote.author}</p> */}
              </Col>
            </Grid>
          ))}
        </Fade>
      </div>
    );
  }
}
