import React, { Component } from "react";
import { Grid, Col } from "gymnast";
import styles from "./styles.module.css";
import { ReactComponent as Logo } from "../../components/Logo/logo.svg";
import { ReactComponent as TheBilTheoryIcon } from "../../components/Icons/TheBilTheoryIcon.svg";

export default class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      yogis: ["hello"]
    };
  }

  render() {
    return (
      <Grid className={styles.wrapper} align="center" justify="center">
        {/* <div className={styles.content}>
          <div className={styles.column}>
            <Logo className={styles.logo} />
          </div>
        </div> */}

        <Col className={styles.subfooter}>
          {new Date().getFullYear()} &copy; &nbsp;
          <strong> Innergy Retreat.</strong> &nbsp;
          <span>Design</span> &nbsp;&amp;&nbsp; <span>Code</span> &nbsp;by
          <a className={styles.tbt} href="https://thebiltheory.com">
            &nbsp;
            <TheBilTheoryIcon
              style={{ width: "22px", height: "auto", margin: "0 8px -10px" }}
            />
            TheBilTheory
          </a>
          .
        </Col>
      </Grid>
    );
  }
}
