import React, { Component } from "react";
import { elastic as Menu } from "react-burger-menu";
import styles from "./styles.css";
import Scrollchor from "react-scrollchor";
import { ReactComponent as Hamburger } from "../Icons/Hamburger.svg";

const menuStyles = {
  bmBurgerButton: {
    position: "absolute",
    width: "36px",
    height: "30px",
    right: "36px",
    top: "36px",
    padding: "16px 15px 16px 20px",
    background: "#ffffff"
  },
  bmBurgerBars: {
    background: "#373a47"
  },
  bmCrossButton: {
    height: "24px",
    width: "24px"
  },
  bmCross: {
    background: "#bf4e4e"
  },
  bmMenu: {
    background: "#ea8a8a",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em"
  },
  bmMorphShape: {
    fill: "#ea8a8a"
  },
  bmItemList: {
    color: "red",
    padding: "0.8em"
  },
  bmItem: {
    display: "flex",
    color: "#fff",
    textDecoration: "none",
    margin: "12px 0"
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)"
  }
};

export default class Navigation extends Component {
  showSettings(event) {
    event.preventDefault();
  }

  render() {
    const { pageWrapId, outerContainerId, isOpen } = this.props;
    return (
      <Menu
        pageWrapId={pageWrapId}
        outerContainerId={outerContainerId}
        right
        // customBurgerIcon={<Hamburger />}
        isOpen={isOpen}
        customBurgerIcon={false}
        styles={menuStyles}
      >
        <Scrollchor to={"#about"}>About</Scrollchor>

        <Scrollchor to={"#team"}>Team</Scrollchor>

        <Scrollchor to={"#location"}>Location</Scrollchor>

        <Scrollchor to={"#package"}>Package</Scrollchor>

        <Scrollchor to={"#schedule"}>Schedule</Scrollchor>

        <Scrollchor to={"#register"}>Register</Scrollchor>
      </Menu>
    );
  }
}
