import React, { Component } from "react";
import { Col, Grid } from "gymnast";
import styles from "./styles.module.css";
import Title from "../../components/Title";
import WithVisible from "../../components/WithVisible";
import Button from "../../components/Button";

const size = {
  large: 6,
  medium: 12,
  small: 12
};

class PriceComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      yogis: ["hello"]
    };
  }

  render() {
    return (
      <Grid className={styles.wrapper} id={this.props.id}>
        <Grid align="center" justify="center" className={styles.content}>
          <Col size={size} className={styles.leftContent}>
            <Title
              className={styles.Title}
              underline={false}
              align="flex-start"
            >
              How much does it cost?
            </Title>
            <div className={styles.description}>
              <span className={styles.caption}>Between</span>
              <span className={styles.price}>
                1800 <span className={styles.currency}>&#8364;</span> - 5000
                &#8364;
              </span>
              <span className={styles.caption}>
                7 Nights, 8 Teachers, 17 Activities{" "}
              </span>
            </div>
          </Col>
          <Col
            className={styles.buttons}
            size={size}
            align="center"
            justify="center"
          >
            <Button title="Join us Now!" light anchor="register" />
            <Button title="What's included?" anchor="package" />
            <a href="tel:+971503797748" className={styles.moreInfo}>
              Need more info? Call us.
            </a>
          </Col>
        </Grid>
      </Grid>
    );
  }
}

const Price = WithVisible(PriceComponent);
export default Price;
