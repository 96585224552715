import React from "react";
import styles from "./styles.module.css";

const Title = ({ children, className, underline = true, align = "center" }) => (
  <h4
    className={`${styles.title} ${className ? className : ""}`}
    style={{ alignItems: align }}
  >
    {children}
    {underline && <span className={styles.line} />}
  </h4>
);
export default Title;
