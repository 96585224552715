import React, { Component } from "react";
import Helmet from "react-helmet";
import StickyHeader from "react-sticky-header";
import { Layout, Grid } from "gymnast";
import "./App.css";

import Hero from "./sections/hero";
import About from "./sections/about";
import Team from "./sections/team";
import Package from "./sections/package";
import Price from "./sections/Price";
import Schedule from "./sections/schedule/index";

import Navigation from "./components/Navigation";

import Accommodation from "./sections/accommodation";
import Footer from "./sections/footer";
import Location from "./sections/Location";
import Contact from "./sections/contact";

import { ReactComponent as Hamburger } from "./components/Icons/Hamburger.svg";
import { ReactComponent as Logo } from "./components/Logo/logo.svg";
import styles from "./sections/hero/styles.module.css";

const Brand = ({ className }) => (
  <div className={`${styles.brand} ${className && className}`}>
    <Logo className={styles.logo} />
    <Grid show="large" className={styles.typed}>
      <span>Innergy Retreat</span>
    </Grid>
  </div>
);

class App extends Component {
  state = {
    isOpen: false
  };

  onOpeMenu() {
    this.setState({ isOpen: true });
  }

  render() {
    const { isOpen } = this.state;

    return (
      <div className="App" id={"outer-container"}>
        <Helmet>
          <title>Innergy Retreat - Best Yoga retreat in Ibiza</title>
          <meta name="description" content="Yoga Retreat" />
        </Helmet>

        <Navigation
          pageWrapId={"innergy-wrap"}
          outerContainerId={"outer-container"}
          isOpen={isOpen}
        />

        <main id="innergy-wrap">
          <StickyHeader
            headerOnly
            className={`${styles.header}`}
            // onSticky={this.onSticky}
            backgroundColor="#ffffff"
            // stickyOffset={40}
            header={
              <div className={styles.headerContent}>
                <Brand className="brand" />
                <div className={styles.Hamburger}>
                  <Hamburger onClick={() => this.onOpeMenu()} />
                </div>
              </div>
            }
          />

          <Hero id="top" />

          <About id="about" />

          <Team id="team" />

          <Schedule id="schedule" />

          <Package id="package" />

          <Price id="price" />

          <Location id="location" />

          <Accommodation id="accomodation" />

          <Contact id="register" />
        </main>
        <Footer />
      </div>
    );
  }
}

export default App;
