import React, { Component } from "react";
import { Prismic } from "prismic.io";
import config from "../../config";
import styles from "./styles.module.css";
import classNames from "classnames/bind";
import Title from "../../components/Title";
import WithVisible from "../../components/WithVisible";

import { wrapGrid } from "animate-css-grid";

const cx = classNames.bind(styles);

// window.CP.PenTimer.MAX_TIME_IN_LOOP_WO_EXIT = 5000;
class Card extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    };

    this.randomNumber = Math.floor(Math.random() * 5) + 1;
  }

  expanHandle() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { expanded } = this.state;
    const { image, name, description, website } = this.props;

    return (
      <div
        className={cx({
          card: true,
          // [`card--${this.randomNumber}`]: true,
          "card--expanded": expanded
        })}
        onClick={() => this.expanHandle()}
      >
        <div className={cx({ expanded })}>
          <div
            className={cx({ card__avatar: true, scale: expanded })}
            style={{ backgroundImage: `url('${image}')` }}
          />
          <div className={styles["card__title"]}>{name}</div>
          <div className={cx({ card__description: true, trim: !expanded })}>
            {description}
          </div>
          <div className={styles["card__website"]}>
            <a href={website} target="_blank">
              {website}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

class Grid extends React.Component {
  componentDidMount() {
    // will automatically clean itself up when dom node is removed
    wrapGrid(this.grid, {
      easing: "backOut",
      stagger: 10,
      duration: 400
    });
  }

  render() {
    let classes = "grid";
    Object.keys(this.props.settings)
      .filter(k => this.props.settings[k])
      .forEach(k => (classes += " " + k));
    return (
      <div className={cx({ grid: true })} ref={el => (this.grid = el)}>
        {this.props.team.map((m, i) => (
          <Card
            key={i}
            name={m.name}
            image={m.image}
            description={m.description}
            website={m.website}
          />
        ))}
      </div>
    );
  }
}

class TeamComponent extends React.Component {
  state = {
    "grid-gap": false,
    "grid-template-columns": false,
    team: null
  };

  componentDidMount() {
    this.getTeam();
  }

  async getTeam() {
    try {
      const call = await Prismic.api(config.api);
      const query = await call.query([
        Prismic.Predicates.at("document.type", "team-member")
      ]);

      const team = query.results
        .map(m => ({
          order: m.rawJSON.order,
          image: m.rawJSON.image.url,
          name: m.rawJSON.name,
          description: m.rawJSON.description,
          website: m.rawJSON.website.url || null
        }))
        .sort((a, b) => a.order - b.order);

      this.setState({
        team
      });

      console.log(this.state);
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { team } = this.state;

    if (!team) {
      return <div>Loading the team ...</div>;
    }

    return (
      <div className={styles.wrapper} id={this.props.id}>
        <div className={styles.content}>
          <Title>Team</Title>
          <Grid settings={this.state} team={team} />
        </div>
      </div>
    );
  }
}

const Team = WithVisible(TeamComponent);
export default Team;
