import React, { Component } from "react";
import ScrollDown from "../../components/ScrollDown";
import styles from "./styles.module.css";
import "react-sticky-header/styles.css";

import QuoteGenerator from "../../components/QuoteGenerator";
// import { ReactComponent as HeroLayer } from "../../components/Misc/HeroLayer.svg";

export default class Hero extends Component {
  render() {
    return (
      <div>
        <div className={styles.wrapper}>
          {/* <HeroLayer className={styles.HeroLayer} /> */}
          <QuoteGenerator />
          {/* <ScrollDown scrollTo="#about" /> */}
        </div>
      </div>
    );
  }
}
