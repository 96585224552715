import React, { Component } from "react";
import { Grid, Col } from "gymnast";
import ReactMapGL, { Marker } from "react-map-gl";

import WithVisible from "../../components/WithVisible";
import styles from "./styles.module.css";
import Title from "../../components/Title";
import CustomMarker from "./CustomMarker";

class LocationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapStyle: "mapbox://styles/thebiltheory/cjpi868o80d472rqj4o8og48i",
      viewport: {
        width: "100%",
        height: 500,
        latitude: 38.967864,
        longitude: 1.37726,
        zoom: 14
        // bearing: 0,
        // pitch: 0
      },
      token:
        "pk.eyJ1IjoidGhlYmlsdGhlb3J5IiwiYSI6ImNqb2JibmN3NTBuYWEzcG1yYmlzdDZ3N28ifQ.8ZSTwgyp00ihik8hi_PSbQ"
    };
  }

  render() {
    const { viewport, mapStyle, token } = this.state;
    const colSize = {
      large: 6,
      medium: 6,
      small: 12
    };
    return (
      <Grid className={styles.wrapper} id={this.props.id} size={"auto"}>
        <Col className={styles.map} size={colSize}>
          {/* <ReactMapGL
            {...viewport}
            width="100%"
            height="500px"
            mapStyle={mapStyle}
            dragPan={false}
            touchAction="pan-y"
            onViewportChange={viewport => this.setState({ viewport })}
            mapboxApiAccessToken={token}
          >
            <CustomMarker
              latitude={viewport.latitude}
              longitude={viewport.longitude}
            />
          </ReactMapGL> */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3102.0751846254425!2d1.3756183!3d38.9679512!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x129947efbf03a015%3A0xe9c9cf42262aa4f7!2sCan+Almaria%2C+Poligono+22%2C+Parcela+164%2C+San+Rafael+De+Se+Creu%2C+07816%2C+Ibiza!5e0!3m2!1sen!2sae!4v1544691976696"
            width="600"
            height="450"
            frameBorder="0"
            style={{ border: "none" }}
          />
        </Col>
        <Col className={styles.details} size={colSize}>
          <Title>Where are we going?</Title>
          <address>
            Can Almaria, Poligono 22, <br />
            Parcela 164, San Rafael De Se Creu, 07816, <br />
            Ibiza Diseminado, <br />
            Sa Bassa Roja, 07816 Sant Antoni de Portmany, <br />
            Illes Balears, <br />
            Spain
          </address>
        </Col>
      </Grid>
    );
  }
}

const Location = WithVisible(LocationComponent);
export default Location;
