import React, { Component } from "react";
import Prismic from "prismic.io";
import { Layout, Root, Grid, Dev, Col } from "gymnast";
import { Blob } from "react-blob";
import styles from "./styles.module.css";
import config from "../../config";
import Title from "../../components/Title";
import Yogis from "../../components/Icons/Yogis";
import WithVisible from "../../components/WithVisible";
import { ReactComponent as YogaGirlStandUp } from "../../components/Illustrations/yogaGirlStandUp.svg";
import { ReactComponent as YinYang } from "../../components/Icons/YinYang.svg";
import Button from "../../components/Button";

const size = {
  large: 6,
  medium: 12,
  small: 12
};

const ListWrapper = ({ children }) => (
  <ul className={styles.listWrapper}>{children}</ul>
);
const ListItem = ({ children }) => (
  <li className={styles.card}>
    <span>{children}</span>
  </li>
);

class AboutComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: [],
      list: []
    };
  }

  componentDidMount() {
    this.getAboutSection();
  }

  async getAboutSection() {
    try {
      const call = await Prismic.api(config.api);
      const query = await call.query([
        Prismic.Predicates.at("document.type", "about_section")
      ]);
      const result = query.results[0].rawJSON;
      const state = {
        title: result.section_title[0].text,
        description: result.description.map(p => p.text),
        list: result.about_list.map(l => l.text)
      };

      this.setState({
        ...state
      });
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    const { title, description, list } = this.state;
    return (
      <Grid
        className={styles.wrapper}
        id={this.props.id}
        size={12}
        align="center"
        justify="center"
      >
        <Col size={size}>
          <Title>{title}</Title>
          <p className={styles.description}>
            Care to know? Come join me this{" "}
            <strong className={styles.date}>May 18th-25th</strong> for a magical
            INNERGY retreat in order to get one step closer to knowing the
            unmasked version of yourself.
          </p>
          {/* {description.map((p, i) => (
            <p className={styles.description} key={i}>
              {p}
            </p>
          ))} */}
          {/*<div className={styles.details}>
            <ListWrapper>
              {list.map((listItem, i) => (
                <ListItem>{listItem}</ListItem>
              ))}
            </ListWrapper>
              </div>*/}
          <Button title="Join us Now" anchor="register" />
        </Col>
        <Col size={size} className={`${styles.illustration}`}>
          <YogaGirlStandUp />
          <Blob size="245px" className={styles.blob} />
        </Col>
      </Grid>
    );
  }
}

const About = WithVisible(AboutComponent);
export default About;
