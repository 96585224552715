import React, { Component } from 'react';
import styles from './styles.module.css';

export default class DayRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({ open: !this.state.open });
  }

  render() {
    const { time, topic, text, yogi } = this.props;
    const { open } = this.state;
    return (
      <div className={styles.detail} onMouseEnter={this.toggle} onMouseLeave={this.toggle}>
        <span className={styles.time}>{time}</span>
        <div className={styles.topic}> {topic}</div>
        <div className={styles.open}>
          <div className={styles.text}>{text && <p>{text}</p>}</div>
          {yogi && (
            <div className="speaker">
              <strong>Yogi</strong>:
              <a href="#" className="gradient-text ">
                {yogi}
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}
