import React, { Component } from "react";
import Prismic from "prismic.io";
import { Carousel } from "react-responsive-carousel";
import { Grid, Col } from "gymnast";
import Gallery from "react-photo-gallery";
import Lightbox from "react-images";
import Title from "../../components/Title";
import WithVisible from "../../components/WithVisible";
import config from "../../config";
import Button from "../../components/Button";

import "./styles.css";
import styles from "./styles.module.css";

class AccommodationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: null
    };
  }

  componentDidMount() {
    this.getGallery();
  }

  async getGallery() {
    try {
      const call = await Prismic.api(config.api);
      const query = await call.query([
        Prismic.Predicates.at("document.type", "image_gallery")
      ]);
      const photos = query.results[0].rawJSON["image-gallery"].map(i => ({
        src: i.image.url,
        width: i.image.dimensions.width,
        height: i.image.dimensions.height
      }));

      this.setState({
        photos
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { photos } = this.state;

    if (!photos) {
      return <div> pictures are loading </div>;
    }

    return (
      <div className={styles.wrapper} id={this.props.id}>
        <Carousel
          className={styles.carousel}
          showArrows
          showIndicators={false}
          showStatus={false}
          infiniteLoop
          verticalSwipe="natural"
          useKeyboardArrows
          autoPlay
          // thumbWidth={100 / photos.length}
          swipeable
          emulateTouch
          // centerMode
        >
          {photos.map((pic, i) => (
            // <div>
            <img
              key={i}
              src={pic.src}
              height="100%"
              alt="Inngery Retreat Accomodation"
            />
            // </div>
          ))}
        </Carousel>
      </div>
    );
  }
}
// class AccommodationComponent extends Component {
//   constructor() {
//     super();
//     this.state = { currentImage: 0 };
//     this.closeLightbox = this.closeLightbox.bind(this);
//     this.openLightbox = this.openLightbox.bind(this);
//     this.gotoNext = this.gotoNext.bind(this);
//     this.gotoPrevious = this.gotoPrevious.bind(this);
//     this.state = {
//       photos: null
//     };
//   }

//   componentDidMount() {
//     this.getGallery();
//   }

//   async getGallery() {
//     try {
//       const call = await Prismic.api(config.api);
//       const query = await call.query([
//         Prismic.Predicates.at("document.type", "image_gallery")
//       ]);
//       const photos = query.results[0].rawJSON["image-gallery"].map(i => ({
//         src: i.image.url,
//         width: i.image.dimensions.width,
//         height: i.image.dimensions.height
//       }));

//       this.setState({
//         photos
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   }

//   openLightbox(event, obj) {
//     this.setState({
//       currentImage: obj.index,
//       lightboxIsOpen: true
//     });
//   }
//   closeLightbox() {
//     this.setState({
//       currentImage: 0,
//       lightboxIsOpen: false
//     });
//   }
//   gotoPrevious() {
//     this.setState({
//       currentImage: this.state.currentImage - 1
//     });
//   }
//   gotoNext() {
//     this.setState({
//       currentImage: this.state.currentImage + 1
//     });
//   }

//   render() {
//     const { photos } = this.state;

//     const colSize = {
//       large: 3,
//       medium: 4,
//       small: 6
//     };

//     const items = [
//       "8 Luxurious en-suit rooms ( Single & Double occupancy",
//       "2 Swimming pools (outdoor",
//       "Tennis Court",
//       "Yurt",
//       "Air-conditioned public areas",
//       "Garden",
//       "Kitchen",
//       "Meditation garden",
//       "Terraces",
//       "Outdoor Yoga Deck",
//       "Outdoor seating area"
//     ];

//     if (!photos) {
//       return <div> pictures are loading </div>;
//     }

//     return (
//       <div className={styles.wrapper} id={this.props.id}>
//         <Title>Accommodation</Title>
//         <div className={styles.details}>
//           <Grid size={12}>
//             {items.map((item, i) => (
//               <Col size={colSize} key={i}>
//                 <div className={styles.item}>{item}</div>
//               </Col>
//             ))}
//             <Col size={colSize} className={styles.cta}>
//               <Button title="Register Now!" anchor="register" />
//             </Col>
//           </Grid>
//         </div>
//         <div className={styles.content}>
//           <div className={styles.gallery}>
//             <Gallery photos={photos} onClick={this.openLightbox} />
//             <Lightbox
//               images={photos}
//               onClose={this.closeLightbox}
//               onClickPrev={this.gotoPrevious}
//               onClickNext={this.gotoNext}
//               currentImage={this.state.currentImage}
//               isOpen={this.state.lightboxIsOpen}
//             />
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

const Accommodation = WithVisible(AccommodationComponent);
export default Accommodation;
