import React, { Component } from "react";
import { Grid, Col } from "gymnast";
import styles from "./styles.module.css";
import Title from "../../components/Title";
import { ReactComponent as YogaGirlStandUp } from "../../components/Illustrations/yogaGirlStandUp.svg";
import { Blob } from "react-blob";
import QueueAnim from "rc-queue-anim";

import { ReactComponent as Calendar } from "../../components/Icons/CalendarOutlined.svg";
import { ReactComponent as YogaClasses } from "../../components/Icons/YogaClasses.svg";
import { ReactComponent as SoundHealing } from "../../components/Icons/SoundHealing.svg";
import { ReactComponent as DailyMeals } from "../../components/Icons/DailyMeals.svg";
import { ReactComponent as BeachOnRequest } from "../../components/Icons/BeachOnRequest.svg";
import { ReactComponent as Meditation } from "../../components/Icons/Meditation.svg";
import { ReactComponent as Accomodation } from "../../components/Icons/Accomodation.svg";
import { ReactComponent as Hiking } from "../../components/Icons/Hiking.svg";
import { ReactComponent as Journaling } from "../../components/Icons/Journaling.svg";
import { ReactComponent as Reading } from "../../components/Icons/Reading.svg";
import { ReactComponent as Transfers } from "../../components/Icons/Transfers.svg";
import { ReactComponent as Sailing } from "../../components/Icons/Sailing.svg";
import { ReactComponent as Dinner } from "../../components/Icons/Dinner.svg";
import { ReactComponent as FreeTime } from "../../components/Icons/FreeTime.svg";
import { ReactComponent as Music } from "../../components/Icons/Music.svg";
import { ReactComponent as Excursion } from "../../components/Icons/Excursion.svg";

import WithVisible from "../../components/WithVisible";

const PackageItem = ({ icon, text, description, key }) => (
  <div className={styles.item} key={key}>
    <i className={styles.icon}>{icon}</i>
    <p className={styles.description}>
      {text}
      {description && <span>{description}</span>}
    </p>
  </div>
);

class PackageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      yogis: ["hello"]
    };
  }

  render() {
    return (
      <div className={styles.wrapper} id={this.props.id}>
        <div className={styles.content}>
          {/* <Blob
            style={{
              height: "50vh",
              width: "50vh",
              maxWidth: "100vw",
              maxHeight: "100vw",
              position: "absolute",
              zIndex: 1,
              backgroundColor: "#ea8a8c",
              color: "white",
              opacity: 0.08
            }}
          /> */}
          <Title>What's Included?</Title>
          <QueueAnim
            appear
            componentProps={{ className: styles.list }}
            leaveReverse
          >
            {/* <Grid> */}
            <PackageItem
              key="1"
              icon={<Calendar />}
              text="8 nights/7 days healing Yoga retreat in Ibiza"
            />
            <PackageItem
              key="2"
              icon={<Accomodation />}
              text="Luxury Accommodation"
              description="single/double occupancy"
            />
            <PackageItem
              key="3"
              icon={<YogaClasses />}
              text="Yoga classes"
              description="Twice a day"
            />
            <PackageItem
              key="4"
              icon={<Meditation />}
              text="Meditations practices"
              description="Twice a day"
            />
            <PackageItem
              key="5"
              icon={<SoundHealing />}
              text="Sound Healing & Gong Bath"
            />
            <PackageItem key="6" icon={<Hiking />} text="Hikes" />
            <PackageItem key="7" icon={<Calendar />} text="Silent Evenings" />
            <PackageItem
              key="8"
              icon={<DailyMeals />}
              text="Daily Meals"
              description="Organic Wholesome, Breakfast/Snack/Lunch/Snack/Dinner"
            />
            <PackageItem
              key="9"
              icon={<Dinner />}
              text="2 Magical Outing Dinner"
            />
            <PackageItem
              key="10"
              icon={<Excursion />}
              text="Excursion to Las Dalias or Joan Hippie Markets"
            />
            <PackageItem
              key="11"
              icon={<Transfers />}
              text="Airport Transfers"
            />
            <PackageItem
              key="12"
              icon={<BeachOnRequest />}
              text="Beach on Request"
            />
            <PackageItem
              key="13"
              icon={<Sailing />}
              text="A day of Sailing around Formentera/ Es Vedra"
            />
            <PackageItem
              key="14"
              icon={<Reading />}
              text="1 Channeling/ reading session"
              description="By one of the best Quantum Healers in the world"
            />
            <PackageItem
              key="15"
              icon={<FreeTime />}
              text="8 Hours of Free Time"
            />
            <PackageItem key="16" icon={<Journaling />} text="Journaling" />

            <PackageItem key="17" icon={<Music />} text="Live Sufi Music" />
            {/* </Grid> */}
          </QueueAnim>
        </div>
      </div>
    );
  }
}

// const Package = PackageComponent;
const Package = WithVisible(PackageComponent);
export default Package;
