import React from "react";
import { BaseControl } from "react-map-gl";
import { ReactComponent as Logo } from "../../components/Logo/logo.svg";

export default class CustomMarker extends BaseControl {
  _render() {
    const { longitude, latitude } = this.props;

    console.log(this._context);

    const [x, y] = this._context.viewport.project([longitude, latitude]);

    const markerStyle = {
      position: "absolute",
      background: "#fff",
      borderRadius: "500px",
      width: "40px",
      height: "40px",
      padding: "8px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0px 2px 4px rgba(0,0,0,0.3)",
      left: x,
      top: y
    };

    console.log(y, x, markerStyle);

    return (
      <div ref={this._containerRef} style={markerStyle}>
        <Logo style={{ height: "30px", width: "30px" }} />
      </div>
    );
  }
}
