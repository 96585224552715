import React, { Component, Fragment } from "react";
import styles from "./styles.module.css";
import "./styles.css";
import classNames from "classnames/bind";
import ZapierForm from "react-zapier-form";
import WithVisible from "../../components/WithVisible";
import { ReactComponent as StandingYogaGirl } from "../../components/Misc/StandingYogaGirl.svg";
import {
  Form,
  Text,
  Select,
  Option,
  RadioGroup,
  Radio,
  TextArea,
  Scope
} from "informed";
import Title from "../../components/Title";
import { ReactComponent as RegisterLayerTop } from "../../components/Misc/RegisterLayerTop.svg";
import { ReactComponent as HeadStandYogaGirl } from "../../components/Misc/HeadStandYogaGirl.svg";
import config from "../../config";
import { encode } from "punycode";

const cx = classNames.bind(styles);

const Success = () => (
  <div className={styles.success}>
    <p className={styles.SuccessTitle}>Thank you for loving yourself.</p>
    <p>Someone will be in touch shortly.</p>
    <StandingYogaGirl />
  </div>
);

const Loading = () => (
  <div className={styles.Loading}>
    <HeadStandYogaGirl />
  </div>
);

class ContactComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      yogis: ["hello"]
    };
  }

  postToGoogleSheets(e) {
    fetch(config.sheets, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      mode: "no-cors",
      data: e
    });

    console.log(e);
  }

  handleSubmit(e) {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": " application/x-www-form-urlencoded" },
      body: encode({ "form-name": "ir-participant", ...e })
    })
      .then(() => alert("Succes!"))
      .catch(error => alert(error));
  }

  render() {
    return (
      <div className={styles.wrapper} id={this.props.id}>
        <RegisterLayerTop className={styles.RegisterLayerTop} />
        <div className={styles.content}>
          <Title>Register</Title>
          <div>
            <ZapierForm
              action="https://hooks.zapier.com/hooks/catch/4139344/ci96z4/"
              honeyPotName="bzz-field"
            >
              {({ error, loading, success }) => {
                return (
                  <div className={styles.form}>
                    {!success &&
                      !loading && (
                        <Fragment>
                          <div className={styles.column}>
                            <input type="hidden" name="bzz-field" />
                            <input
                              type="text"
                              id="full_name"
                              placeholder="Full Name"
                              name="full_name"
                              required
                            />
                            <input
                              type="tel"
                              id="phone_number"
                              name="phone_number"
                              placeholder="Phone Number"
                              required
                            />
                            <input
                              type="email"
                              id="email_address"
                              name="email_address"
                              placeholder="bil@youremail.com"
                              required
                            />

                            <input
                              type="date"
                              name="date_of_birth"
                              id="birthday"
                              placeholder="Date of Birth"
                              required
                            />

                            <div className={styles.radio}>
                              <label htmlFor="gender-male">
                                <input
                                  type="radio"
                                  id="gender-male"
                                  name="gender"
                                  value="Male"
                                />
                                Male
                              </label>

                              <label htmlFor="gender-female">
                                <input
                                  type="radio"
                                  id="gender-female"
                                  name="gender"
                                  value="Female"
                                />
                                Female
                              </label>
                            </div>

                            <select
                              id="yoga-knowledge"
                              name="yoga_knowledge"
                              defaultValue=""
                            >
                              <option value="" disabled>
                                Knowledge of yoga:
                              </option>
                              <option value="Beginner">Beginner</option>
                              <option value="Intermediate">Intermediate</option>
                              <option value="Advanced">Advanced</option>
                            </select>

                            <select
                              id="dietary-requirements"
                              name="dietary_requirements"
                              defaultValue=""
                            >
                              <option value="" disabled>
                                Dietary requirements:
                              </option>
                              <option value="Vegetarians">Vegetarians</option>
                              <option value="Pescaterian">Pescaterian</option>
                              <option value="Gluten Free">Gluten Free</option>
                              <option value="Dairy Free">Dairy Free</option>
                              <option value="Others">Others</option>
                            </select>

                            <input
                              type="text"
                              id="allergies"
                              name="allergies"
                              placeholder="Do you have Allergies?"
                            />

                            <select
                              id="fitness-level"
                              name="level_of_fitness"
                              defaultValue=""
                            >
                              <option value="" disabled>
                                Level of fitness:
                              </option>
                              <option value="Beginner">Beginner</option>
                              <option value="Intermediate">Intermediate</option>
                              <option value="Advanced">Advanced</option>
                            </select>

                            <div className={styles.radio}>
                              <label htmlFor="occupancy-single">
                                <input
                                  type="radio"
                                  id="occupancy-single"
                                  name="occupancy"
                                  value="Single"
                                />
                                Single
                              </label>

                              <label htmlFor="occupancy-sharing">
                                <input
                                  type="radio"
                                  id="occupancy-sharing"
                                  name="occupancy"
                                  value="Sharing"
                                />
                                sharing
                              </label>
                            </div>
                          </div>
                          <div className={styles.column}>
                            <textarea
                              id="textarea-reason"
                              name="why_this_retreat"
                              placeholder="Why do you want to attend this retreat?"
                            />

                            <textarea
                              id="textarea-achievment"
                              name="achievment"
                              placeholder="What are you hoping to achieve?"
                            />

                            <input
                              type="text"
                              id="injuries"
                              name="injuries"
                              placeholder="Do you have Injuries?"
                            />

                            <textarea
                              id="textarea-about"
                              name="about_participant"
                              placeholder="Anything else you like us to know about you?"
                            />
                            <button type="submit">Count me in!</button>
                          </div>
                        </Fragment>
                      )}
                    {loading && <div>Loading...</div>}
                    {error && (
                      <div>Something went wrong. Please try again later.</div>
                    )}
                    {success && <Success />}
                  </div>
                );
              }}
            </ZapierForm>
          </div>
        </div>
      </div>
    );
  }
}

const Contact = WithVisible(ContactComponent);
export default Contact;
