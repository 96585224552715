import React, { Component } from "react";
import styles from "./styles.module.css";
import Scrollchor from "react-scrollchor";

export default class Button extends Component {
  render() {
    const { title, type, onClick, light, anchor, disabled } = this.props;

    const isLight = light ? styles.light : "";

    return (
      <button
        onClick={onClick}
        className={`${styles.button} ${isLight.toString()}`}
        type={type}
        disabled={disabled}
      >
        {anchor && <Scrollchor to={`${anchor}`}>{title}</Scrollchor>}
        {!anchor && <span>{title}</span>}
      </button>
    );
  }
}
