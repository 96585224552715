import React, { Component } from "react";
import dayjs from "dayjs";
import QueueAnim from "rc-queue-anim";
import ScrollMenu from "react-horizontal-scrolling-menu";
import ReactFitText from "react-fittext";
import DayDetail from "../../components/DayDetail";
import Prismic from "prismic.io";
import { Grid, Col } from "gymnast";
import config from "../../config";
import styles from "./styles.module.css";
import Title from "../../components/Title";
import WithVisible from "../../components/WithVisible";
import Button from "../../components/Button";
import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import { ReactComponent as HeroLayer } from "../../components/Misc/HeroLayer.svg";
import { ReactComponent as SectionLayerTop } from "../../components/Misc/SectionLayerTop.svg";

const ScheduleItem = ({ time, description, host }) => {
  const sizeTime = {
    large: 2,
    medium: 3,
    small: 12
  };

  const sizeDescription = {
    large: 10,
    medium: 9,
    small: 12
  };
  return (
    <Grid className={styles.ScheduleItemWrapper}>
      <Col className={styles.time} size={sizeTime}>
        <ReactFitText compresson={3} minFontSize={14} maxFontSize={21}>
          <span>{time}</span>
        </ReactFitText>
      </Col>
      <Col className={styles.description} size={sizeDescription}>
        <ReactFitText compressor={3} minFontSize={14} maxFontSize={16}>
          <p>{description}</p>
        </ReactFitText>
        {host && <span className={styles.host}>With {host}</span>}
      </Col>
    </Grid>
  );
};

class ScheduleComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schedule: null
    };
  }

  componentDidMount() {
    this.getSchedule();
  }

  // componentWillUnmount() {
  //   if (this.getSchedule) {
  //     this.getSchedule.cancel();
  //   }
  // }

  async getSchedule() {
    try {
      const call = await Prismic.api(config.api);
      const query = await call.query([
        Prismic.Predicates.at("document.type", "schedule_day")
      ]);
      const schedule = query.results
        .map(day => ({
          id: day.id,
          day: dayjs(day.rawJSON.day).format("dddd DD MMMM"),
          unix: dayjs(day.rawJSON.day).unix(),
          schedule: day.rawJSON.schedule.map(time => ({
            time: time.time[0].text,
            description: time.description[0].text,
            host: time.host_speaker
          }))
        }))
        .sort((a, b) => a.unix - b.unix);

      this.setState({
        schedule
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { schedule } = this.state;

    if (!schedule) {
      return <div>Hold tight ... </div>;
    }

    return (
      <div className={styles.wrapper} id={this.props.id}>
        <SectionLayerTop className={styles.SectionLayerTop} />
        <HeroLayer className={styles.HeroLayer} />
        <Title className={styles.title}>Schedule</Title>
        <p>
          <span className={styles.scrollIcon} />
          Scroll from right to left for more days.
        </p>
        <Tabs
          name="schedule-section"
          renderActiveTabContentOnly
          className={styles.content}
        >
          <Grid className={styles.tabnav} size="auto">
            <div className={styles.tabnavWrapper}>
              {schedule !== null &&
                schedule.map((d, i) => (
                  <Col key={i} size="fit">
                    <TabLink
                      to={d.id}
                      className={styles.button}
                      activeClassName={styles["button-active"]}
                    >
                      {d.day}
                    </TabLink>
                  </Col>
                ))}
            </div>
          </Grid>

          <div className={styles.TabContent}>
            <div className={styles.content}>
              {schedule !== null &&
                schedule.map((d, i) => (
                  <TabContent
                    for={d.id}
                    key={i}
                    className={styles.ScheduleContentWrapper}
                  >
                    <QueueAnim appear leaveReverse>
                      {d.schedule.map((day, index) => (
                        <ScheduleItem
                          key={index}
                          time={day.time}
                          description={day.description}
                          host={day.host}
                        />
                      ))}
                    </QueueAnim>
                  </TabContent>
                ))}
            </div>
          </div>
        </Tabs>
        <div className={styles.footer}>
          <Button title="Join Now" anchor="register" />
        </div>
      </div>
    );
  }
}

const Schedule = WithVisible(ScheduleComponent);
export default Schedule;
