import React, { Component } from "react";
import styles from "./styles.module.css";
import OnVisible from "react-on-visible";

function WithVisible(WrappedComponent) {
  return class HOC extends Component {
    render() {
      return (
        <OnVisible
          bounce
          percent={5}
          visibleClassName={styles.visible}
          className={styles.wrapper}
        >
          {<WrappedComponent {...this.props} />}
        </OnVisible>
      );
    }
  };
}

export default WithVisible;
